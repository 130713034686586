<template>
  <div class="game-panel">
    <div class="game-panel-inner">
      <div class="leaderboard">
        <div class="content-wrap">
          <div class="content">
            <div class="texts-wrap">
              <span class="home-title-head font-ang">Miner's Rush</span>
              <span class="home-title font-ang">Play</span>
            </div>

            <div class="col-12 col-md-12 col-xs-12">
              <div class="panel">
                  <div class="panel-content">
                    <div class="col-np-12 col-np-md-12 col-np-xs-12">
                      
                      <div class="col-np-12 col-np-md-12 col-np-xs-12">
                        <div class="beta-label">
                          <span class="play-version">Version: 1.4.4</span>
                        </div>
                      </div>
                      <div class="col-np-6 col-np-md-12 col-np-xs-12">
                        <div class="play-button-container-1">
                          <a href="https://play.google.com/store/apps/details?id=com.btc.dwarves" target="_blank">
                            <div class="play-button">
                              <span class="font-ang">Google Play</span>
                              <span class="play-version"><img src="@/assets/play/platform-android.png"> </span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="col-np-6 col-np-md-12 col-np-xs-12">
                        <div class="play-button-container-1">
                          <a href="https://apps.apple.com/lv/app/miners-rush/id6499256659" target="_blank">
                            <div class="play-button">
                              <span class="font-ang">Apple Store</span>
                              <span class="play-version"><img src="@/assets/play/platform-ios.png"> </span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="col-np-6 col-np-md-12 col-np-xs-12">
                        <div class="play-button-container-2">
                          <a href="https://play.minersrush.xyz/v144/" target="_blank">
                            <div class="play-button">
                              <span class="font-ang">Play on WEB</span>
                              <span class="play-version"><img src="@/assets/play/platform-webgl.png"></span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="col-np-6 col-np-md-12 col-np-xs-12">
                        <div class="play-button-container-2">
                          <a href="https://t.me/playdeckbot/game?startapp=eyJnYW1lIjp7ImtleSI6InlOUHAwZWJ1dWYiLCJyYXciOnt9fX0=" target="_blank">
                            <div class="play-button">
                              <span class="font-ang">Telegram</span>
                              <span class="play-version"><img src="@/assets/play/platform-webgl.png"></span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

            </div>
            
          </div>
        </div>
        <div class="top-divider"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {registerUser} from "@/api";
import store from "@/store";

export default {
  name: 'Play',
  props: {

  },
  data() {
    return {
      store,
      
    }
  },
  methods: {
    async submit(){

    },

  },
  mounted() {
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
.panel {
  overflow: hidden;
}
.gray {
  filter: grayscale(100%);
}
.beta-label {
  display: block;
  text-align: center;
  margin: 0px 0 15px;
  text-shadow: 1px 1px 2px #00000088;
}
.beta-label .play-version {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

.play-button-container-1, .play-button-container-2 {

}
.play-button {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 220px;
  height: 70px;
  cursor: pointer;
  transition: transform 0.3s ease;
  position: relative;
  margin: 0px auto;
  background-image: url("./../assets/new/button_green.png");
}

.play-button:hover {
  transform: scale(1.05);
}
.play-button:active {
  transform: scale(0.98);
}
.play-button span {
  display: block;
  line-height: 70px;
  font-weight: bold;
  font-size: 24px;
  color: #fff;
  transition: transform 0.3s ease;
  text-shadow: 2px 2px  2px #00000088;
  text-align: center;
}

.play-button span.play-version {
  font-size: 16px;
  color: #333;
  text-shadow: none;
  bottom: 0px;
  display: block;
  left: 0px;
  right: 0px;
  top: 0px;
}
.play-button-container-1 .play-button img, .play-button-container-2 .play-button img {
  position: absolute;
  width: 50px;
  top: -5px;
  left: -15px;
}

.leaderboard {
  padding-top: 30px;
}
.logo {
  float: left;
}
.logo img {
  width: 75px;
  height: 75px;
  margin-right: 20px;
}
.content-wrap {
  min-height: 450px;
}
.top-divider {
  margin-top: 0px;
}
p {
  color: #fff;
}
p span {
  display: inline-block;
  width: 150px;
}
h2 {
  font-size: 28px;
  color: #FFF500;
}

@media screen and (min-width: 500px) {
  .play-button span {
    font-size: 24px;
  }
}
</style>
