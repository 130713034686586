<template>
  <transition
      name="fade-and-fly"
  >
    <div class="login" v-show="!loading && store.state.module.popup == 'metamask'">
      <div class="login-panel">
        <div class="panel" v-if="!loading">
          <div class="panel-top">
            
          </div>
          <div class="panel-middle">
            <div class="panel-content">

              <p>Your browser doesn't support Web3 functionality. Please use MetaMask browser.</p>
              <p>If your wallet is locked, you might need to revisit this site and click the button again.</p>
              <div class="delete-button"><a :href="'https://metamask.app.link/dapp/minersrush.xyz/mobileWallet?token='+store.getters.getToken">Open MetaMask</a></div>
            </div>
            <div class="panel-footer">
            </div>
          </div>
          <div class="panel-bottom"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from "vue";
import {authenticateUser, getUserProfile, registerUser} from "@/api";
import store from "@/store";
import {color} from "chart.js/helpers";


export default {
  name: 'ErrorPopup',
  props: {

  },
  data() {
    return {
      loading: true,
      store,
    }
  },
  methods: {


  },
  mounted() {
    setTimeout(() => {
      //this.loading = false;
    }, 100);
    this.loading = false;
    console.log("popup shown???")
  },

}
</script>

<style lang="scss" scoped>
p {
  color: #fff;
  text-align: center;
  line-height: 1.5;
  font-size: 20px;
}
.delete-button {
  width: 250px;
  height: 60px;
  background: url('@/assets/new/button_green.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
  line-height: 60px;
  text-align: center;
  display: block;
  margin: 10px auto 0px;
  font-size: 24px;
  cursor: pointer;
  text-shadow: 2px 2px 2px #00000088;
  font-weight: bold;
  
}
.delete-button a {
  color: #fff;
  text-decoration: none;
}
.error-container {
  display: block;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
}
.login {
  position: absolute;
  top: 150px;
  left: calc(50% - 235px);
  /* right: 0px; */
  z-index: 150;
}
.login-panel {
  margin: 0px auto;
  width: auto;
  min-width: 350px;
  position: relative;
  max-width: 500px;
}
.error {
  color: #ff8585;
  text-align: center;
  font-size: 20px;
}
.fade-and-fly-enter-active,
.fade-and-fly-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}

.fade-and-fly-enter,
.fade-and-fly-leave-to {
  opacity: 0;
  transform: translateY(-200px);
}

.fade-and-fly-leave {
  opacity: 0;
  transform: translateY(-200px);
}
.close-cross {
  position: absolute;
  width: 60px;
  height: 60px;
  top: -25px;
  right: -25px;
  cursor: pointer;
  background: url(https://localhost:8081/img/button-close.9fd8feb2.png) no-repeat;
  background-size: 100% 100%;
  z-index: 20;
}
</style>
