<template>
  <div class="HomeTexts">
    <div class="texts-wrap">
      <span class="home-title-head font-ang">Dwarven Mining Co. Presents</span>
      <span class="home-title font-ang">Miner's Rush</span>
    </div>

    <div class="panel with-header" v-if="!loading">
      
          <div class="pot-header font-ris">DAILY TOURNAMENT</div>
          <div class="pot-title font-ris">Today's Jackpot</div>
          <div class="pot-amount font-ang">{{tournamentPrize}} {{tournamentPrize != '-' ? 'CORE' : '' }}</div>

          <div class="timer-label">Remaining tournament time:</div>
          <div class="timer">{{time}}</div>
      
          <div class="pot-deco deco-left"></div>
        </div>


    
        
      
    
    <span class="home-subtitle font-ris">
      <p>Miners Rush is a match-3 game built on CoreDAO blockchain! Match gems, complete challenges, and play in brand-new levels in our daily tournaments for HUUUUGE prizes!</p>
    </span>
    
  </div>
</template>

<script>
import Vue from "vue";
import router from "@/router";
import {getActiveDailyTournament, getCurrentTournament, getNextTournament} from "@/api";
import {Web3} from "web3";

export default {
  name: 'HomeTexts',
  props: {

  },
  data() {
    return {
      loading: false,
      router,
      tournamentPrize: '-',
      tournamentEnd: null,
      endDate: '',
      interval : null,
      time: '-',
      
      currentEnded: false,
      nextKnown: false
    }
  },
  methods: {
    async updateTimer() {
      if(this.endDate == '')
        return;
      // Get the current time in the user's local time zone
      const currentTime = new Date();

      const targetDateTime = new Date(this.endDate);
      
      // Calculate the time difference in milliseconds
      const timeDifference = targetDateTime.getTime() - currentTime.getTime();

      // Check if the target time has passed
      if (timeDifference <= 0 && this.interval) {
        clearInterval(this.interval); // Stop the timer
        console.log("Countdown complete!"); // Or update UI accordingly
        if(!this.currentEnded) {
          this.currentEnded = true;
        }
        if(this.currentEnded && !this.nextKnown) {
          const resp = await getNextTournament(null, null);
          if(resp && resp.status == 200) {
            this.endDate = resp.data.nextTournamentStartTime;
            this.nextKnown = true;
            return;
          }
        }
        if(this.nextKnown && this.currentEnded) {
          const resp = await getCurrentTournament(null, null);
          if(resp && resp.status == 200) {
            this.tournamentPrize = resp.data.prizePool;
            this.endDate = resp.data.endDate;
          }
        }
        return;
      }

      // Convert milliseconds to hours, minutes, and seconds
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      // Format the remaining time
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      // Update the timer display (replace this with your own logic)
      this.time = formattedTime;
      //console.log(formattedTime);
    }
  },
  async mounted() {
    const resp = await getCurrentTournament(null, null);
    if(resp && resp.status == 200) {
      let web3 = new Web3(window.ethereum);
      if(resp.data.prizePool != null)
        this.tournamentPrize = web3.utils.fromWei(resp.data.prizePool, 'ether');
      this.endDate = resp.data.endDate;
      
      this.interval = setInterval(() => {
        this.updateTimer();
      }, 1000);
    }
  },
  beforeUnmount() {
    if(this.interval)
      clearInterval(this.interval);
  }
}
</script>

<style lang="scss" scoped>
.timer-label {
  color: #fff;
  text-align: center;
  position: relative;
  display: block;
}
.timer {
  color: #fff;
  text-align: center;
  z-index: 20;
  position: relative;
  font-size: 20px;
  background: url('@/assets/new/frame.png') no-repeat;
  background-position: center center;
  padding: 7px 0;
  background-size: 150px 37px;
  margin: 0px 0 -20px 0;
}

.texts-wrap {
  margin-top: 70px;
  margin-bottom: 20px;
  font-weight: bold;
}
.home-title-head {
  display: block;
  margin-top: 25px;
  font-size: 22px;
}
.home-title {
  font-size: 46px;
}

.panel .panel-header span {
  line-height: 35px;
  padding-top: 5px;
}
.pot-header {
  background: linear-gradient(to bottom, #FFF500, #FFA800);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(3px 2px 0px rgba(0, 0, 0, 0.8));
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
.pot-title {
  text-align: center;
  color: #fff;
  font-size: 30px;
  padding: 10px 0;
}
.pot-amount {
  background: linear-gradient(to bottom, #FFF500, #FFA800);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Firefox Support */
  background-clip: text;
  color: transparent;
  mask-image: linear-gradient(0deg, #FFC100 20%, #FFFF00 55.2%, #FFC600 100%);
  -webkit-mask-image: linear-gradient(0deg, #FFC100 20%, #FFFF00 55.2%, #FFC600 100%);
  text-align: center;
  font-size: 46px;
  line-height: 62px;
  filter: drop-shadow(3px 2px 0px rgba(0, 0, 0, 0.8));
  margin-bottom: 20px;
  font-weight: bold;
  
}
.home-subtitle p {
  font-size: 20px;
  text-shadow: 1px 1px 0px #fff;
  margin: 70px 0 0 0;
  color: #3A3A3A;
}


.pot-deco {
  position: absolute;
  z-index: 25;
  background-size: 100%;
  background-repeat: no-repeat;
}
.deco-left {
  background-image: url("./../../assets/new/chect.png");
  bottom: -55px;
  left: -100px;
  width: 153px;
  height: 153px;
}

.HomeTexts {
  margin-top: 90px;
}
@media screen and ( min-width: 550px ) {
  .home-title {
    font-size: 52px;
  }
  .home-title-head {
    font-size: 24px;
  }
  .texts-wrap {
    margin-top: 70px;
  }
  .HomeTexts {
    margin-top: 130px;
  }
  .deco-left {
    bottom: -55px;
    left: -150px;
    width: 243px;
    height: 243px;
  }
  .deco-right {
    bottom: -80px;
    right: -25px;
    width: 144px;
    height: 112px;
  }
  .pot-header {
    font-size: 38px;
  }
  .pot-title {
    font-size: 38px;
    padding: 20px 0;
  }
  .pot-amount {
    font-size: 56px;
    line-height: 62px;
    margin-bottom: 20px;
  }
}
.home-subtitle {
  display: none;
}
@media screen and ( min-width: 767px ) {
  .HomeTexts {
    margin-top: 130px;
  }
  .texts-wrap {
    margin-top: 70px;
  }
  .home-subtitle {
    display: block;
  }
}
</style>
