<template>
  <div class="game-panel">
    <div class="game-panel-inner">
      <div class="leaderboard">
        <div class="content-wrap">
          <div class="content">
            <div class="texts-wrap">
              <div class="logo">
                <img src="./../assets/logo.png" @click="store.commit('goTo','/')">
              </div>
              <span class="home-title-head font-ang">Miner's Rush</span>
              <span class="home-title font-ang">Affiliate program</span>
            </div>

            <div class="col-12 col-md-12">
              <div class="panel">
                <div class="panel-top">
                </div>
                <div class="panel-middle">
                  <div class="panel-content">
                    <div class="col-np-18" >
                      <form class="change-pass-form">
                        <input type="text" placeholder="First Name...">
                        <input type="text" placeholder="Last Name...">
                        <input type="text" placeholder="Email...">
                        <input type="text" placeholder="Phone Number...">
                        <input type="text" placeholder="Organization Name...">
                        <input type="text" placeholder="Organization Size...">
                        <input type="text" placeholder="Link to social media (separate by semicolons)...">
                        <textarea placeholder="Tell us more about your organization..."></textarea>
                        <input type="submit" value="Submit">
                      </form>
                      
                    </div>
                  </div>
                </div>
                <div class="panel-bottom"></div>
              </div>

            </div>



          </div>
        </div>
        <div class="top-divider"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {registerUser} from "@/api";
import store from "@/store";

export default {
  name: 'AffiliateProgram',
  props: {

  },
  data() {
    return {
      store,
      
    }
  },
  methods: {
    async submit(){

    },

  },
  mounted() {
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
.leaderboard {
  padding-top: 30px;
}
.home-title {
  font-size: 60px;
  line-height: 50px;
  display: block;
}
.logo {
  float: left;
}
.logo img {
  width: 75px;
  height: 75px;
  margin-right: 20px;
}
.content-wrap {
  min-height: 450px;
}
.top-divider {
  margin-top: 0px;
}
p {
  color: #fff;
}
p span {
  display: inline-block;
  width: 150px;
}
h2 {
  font-size: 28px;
  color: #FFF500;
}

.change-pass-form input, .change-pass-form textarea {
  width: 50%;
  margin: 0px 0 15px;
  display: block;
  height: 40px;
  border-radius: 15px;
  outline: navajowhite;
  border: navajowhite;
  box-shadow: 1px 1px 0px #666, 1px 1px 0px inset #000;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: 0 20px;
}
.change-pass-form textarea {
  height: 200px;
  padding: 20px;
}
::placeholder {
  color: #999;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #999;
}

</style>
