<template>
  <div class="M_Home">
      <div class="content-wrap">
        <div class="content">
              <div class="col-np-12 col-md-12 col-xs-12">
                <div class="col-11 col-md-12 col-xs-12">
                  <HomeTexts></HomeTexts>
                </div>
                <div class="col-1 col-md-12 col-xs-12">
                  <NotLoggedInPanel></NotLoggedInPanel>
                </div>
              </div>
              <div class="col-np-12 col-np-md-12 col-np-xs-12 play-container">
                
                <div class="play-button-container">
                  <div class="play-button" @click="store.commit('goTo', '/play')">
                    <span class="font-ang">PLAY</span>
                  </div>
                </div>
              </div>
        </div>
      </div>
    
      <div class="top-divider"></div>
     
      
      <MoneyBreakdown></MoneyBreakdown>
    
    
</div>
</template>

<script>
import Vue from "vue";
import NotLoggedInPanel from "./../components/Home/NotLoggedInPanel.vue";
import HomeTexts from "@/components/Home/HomeTexts.vue";
import MoneyBreakdown from "@/components/Home/MoneyBreakdown.vue";
import store from "@/store";
import {getActiveDailyTournament} from "@/api";
export default {
  name: 'Home',
  components: {
    MoneyBreakdown,
    HomeTexts, 
    NotLoggedInPanel
  },
  props: {

  },
  data() {
    return {
      store,
      openedTab: 0,
      loading: true,
    }
  },
  methods: {
    login() {
      this.$store.commit('setPopup', 'login');
    }
  }
}
</script>

<style scoped>

.play-container {
  height: 150px;
  margin-top: 120px;
  margin-bottom: 30px;
}
.play-button-container {
  width: 450px;
  margin: 0px auto;
  z-index: 100;
  position: relative;
}
.beta-label span {
  display: block;
  text-align: center;
}
.play-button {
  background-image: url('./../assets/new/button_green.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 250px;
  height: 69px;
  cursor: pointer;
  transition: transform 0.3s ease;
  position: relative;
  margin: 0px auto;
  cursor: pointer;
}

.play-button:hover {
  transform: scale(1.05);
}
.play-button:active {
  transform: scale(0.98);
}
.play-button span {
  display: block;
  font-size: 34px;
  color: #fff;
  transition: transform 0.3s ease;
  text-shadow: 1px 2px #000;
  font-weight: bold;
  text-align: center;
  line-height: 69px;
}
.play-button-container-1 .play-button span {
  bottom: 35px;
  left: 75px;
}
.play-button-container-2 .play-button span, .play-button-container .play-button span {
  bottom: 30px;
  left: 125px;
}
.play-button-container-1 .play-button img, .play-button-container-2 .play-button img, .play-button-container .play-button img {
  position: absolute;
  width: 60px;
  top: 40px;
  left: 20px;
}
.play-button-container-2 .play-button img, .play-button-container .play-button img {
  left: auto;
  right: 0px;
  top: 85px;
}
@media screen and ( min-width: 580px ) {
  .beta-label {
    left: calc(50% - 90px - 150px);
  }
  .play-button-container-1 {
    left: calc(50% - 175px + 100px);
  }
  .play-button-container-2 {
    left: calc(50% - 150px + 100px);
  }
}
@media screen and ( min-width: 767px ) {
  .play-container {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .play-button-container-1, .play-button-container-2 {
    bottom: 0px;
  }
  .play-button-container-1 {
    left: calc(50% - 175px - 200px);
  }
  .play-button-container-2 {
    left: calc(50% - 150px + 200px);
  }
  .beta-label {
    bottom: 0px;
    left: calc(50% - 90px);
  }
}
  @keyframes play-button {
  0% {transform: rotate(-5deg)}
  50% {transform: rotate(5deg)}
  100% {transform: rotate(-5deg)}
  
}
.unit {
  background: url('./../assets/unit.png');
  background-size: 100% 100%;
  width: 300px;
  height: 600px;
  position: absolute;
  right: auto;
  left: 50%;
  bottom: 190px;
  z-index: 1;
}
@media screen and ( min-width: 767px ) { 
  .unit {
    right: calc(50% - 630px);
    left: auto;
    top: 150px;
  }
}
</style>
