<template>
  <div class="game-panel">
    <div class="game-panel-inner">
      <div class="leaderboard">
        <div class="content-wrap">
          <div class="content">
            <div class="texts-wrap">
              <span class="home-title-head font-ang">Miner's Rush</span>
              <span class="home-title font-ang">Prizes</span>
            </div>

            <div class="col-12 col-md-12 col-xs-12">
              <div class="panel" style="margin-bottom: 40px;">
                <div class="panel-top">
                </div>
                <div class="panel-middle">
                  <div class="panel-content">
                    <div class="col-12 col-md-12 col-xs-12">
                      <h2 class="font-ang">Prize Pool</h2>

                      <p>Every day players can participate in our Daily Tournament for big prizes! The Daily Tournament has a minimum guaranteed prize, but the more people that play, the bigger the prize gets! Once tournament day starts, total jackpot of the day stays fixed.</p>
                      <p>This is a game of skill where the players with the highest score (and in the case of a tie- the player who obtained the score first) wins!</p>

                      <p>Players can play for free in the campaign mode to hone their mining skills but will need an NFT Entry Ticket to compete in the Daily Tournament. NFT Entry Tickets can be purchased here and redeemed in-game. One NFT Entry Ticket allows access to one Daily Tournament match.</p>

                      <div class="col-12 col-md-12 col-xs-12">
                        <h2 class="font-ang">Prize Breakdown</h2>
                        <div class="col-np-4 col-np-md-4 col-np-xs-4 top-pool">
                          <img src="@/assets/2Cup.png">
                          <span>20% of the Prize Pool</span>
                        </div>
                        <div class="col-np-4 col-np-md-4 col-np-xs-4 top-pool">
                          <img src="@/assets/1Cup.png">
                          <span>70% of the Prize Pool</span>
                        </div>
                        <div class="col-np-4 col-np-md-4 col-np-xs-4 top-pool">
                          <img src="@/assets/3Cup.png">
                          <span>10% of the Prize Pool</span>
                        </div>
                        <div class="col-np-12 col-np-md-12 col-np-xs-12">
                          <div class="col-10 col-np-md-12 col-np-xs-12">
                            <p class="mb-2"><span>4-6 Place</span><span>Free NFT Entry Ticket</span></p>
                            
                            <!--<p><span>7-10 Place</span><span>200 Gold Coins</span></p>-->
                            <p><span>7-8 Place</span><span>Power Up - Miner's Cart</span></p>
                            <p><span>9-10 Place</span><span>Power Up - Rainbow Pack</span></p>
                            <p><span>11-12 Place</span><span>Power Up - PickAxe</span></p>
                            <p><span>13-14 Place</span><span>Power Up - Shuffle</span></p>
                            <!--<p><span>31-40 Place</span><span>15 Gold Coins</span></p>
                            <p><span>41-50 Place</span><span>10 Gold Coins</span></p>-->
                            
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="panel-bottom"></div>
              </div>

            </div>



          </div>
        </div>
        <div class="top-divider"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {registerUser} from "@/api";
import store from "@/store";

export default {
  name: 'Prizes',
  props: {

  },
  data() {
    return {
      store,
      
    }
  },
  methods: {
    async submit(){

    },

  },
  mounted() {
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
.panel-content { overflow: hidden}
.top-pool img {
  width: 100px;
  margin: 0px auto;
  position: relative;
  display: block;
}
.top-pool span {
  display: block;
  color: #fff;
  text-align: center;
}
.leaderboard {
  padding-top: 30px;
}
.logo {
  float: left;
}
.logo img {
  width: 75px;
  height: 75px;
  margin-right: 20px;
}
.content-wrap {
  min-height: 450px;
}
.top-divider {
  margin-top: 0px;
}
p {
  color: #fff;
}
p span {
  display: inline-block;
  width: 50%;
  text-align: center;
}
h2 {
  font-size: 28px;
  color: #FFF500;
}

.pie1 {
  background: url("@/assets/misc/pie1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 360px;
  height: 360px;
}
.pie-descr {
  color: #eee;
}
.pie-descr span {
  text-align: center;
  display: block;
}
.prize-img {
  position: relative;
  display: block;
  right: 0px;
  bottom: -100px;
  width: 500px;
  margin-top: -100px;
}
@media screen and ( min-width: 767px ) {
  .prize-img {
    right: 40px;
    bottom: -280px;
  }
}
</style>
