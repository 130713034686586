import { createStore } from "vuex";
import router from "@/router";
import Cookies from "js-cookie";
// Define your modules if needed
var module = {
    state: {
        popup: "",
        loading: false,
        shopStep: "",
        token: "",
        refreshToken: "",
        loggedIn: false,
        user: {
            id: 0,
            username: "",
            walletsWithValidNFTs: [],
            powerUps: [],
            coins: 0,
            wallet: "",
            exp: 0,
            silver: 0,
            role: '',
            myReferralCode: ''
        },
        connectedWallet: "",
        error: "",
        waitingForProfile: false,
        isMobile: false,
        chains: [],
        selectedChain: null //default CORE
    },
    mutations: {
        //@ts-ignore
        setPopup: function (state, value) {
            state.popup = value;
        },
        //@ts-ignore
        setToken: function (state, value) {
            state.token = value;
            Cookies.set("token", value);
        },
        //@ts-ignore
        setRefreshToken: function (state, value) {
            state.refreshToken = value;
            Cookies.set("refresh_token", value);
        },
        //@ts-ignore
        clearPopup: function (state) {
            state.popup = "";
            state.error = "";
        },
        //@ts-ignore
        setUserName: function (state, value) {
            state.user.username = value;
        },
        //@ts-ignore
        setUserWallet: function (state, value) {
            //console.log("Setting the wallet in store??")
            state.connectedWallet = value;
        },
        removeUserWallet: function (state, value) {
            state.connectedWallet = "";
        },
        //@ts-ignore
        setUser: function (state, value) {
            state.user.id = value.id;
            state.user.username = value.username;
            state.user.powerUps = value.powerUps;
            state.user.coins = value.coins;
            state.user.wallet = value.wallet;
            state.user.exp = value.exp;
            state.user.silver = value.silver;
            state.user.role = value.role;
            state.user.myReferralCode = value.myReferralCode;
        },
        //@ts-ignore
        setUserWallets: function (state, value) {
            state.user.walletsWithValidNFTs = value;
        },
        setLoggedIn: function (state, value) {
            state.loggedIn = value;
        },
        goTo: function (state, value) {
            if (router.currentRoute.value.path == value)
                return;
            router.push(value);
            state.popup = "";
            state.error = "";
        },
        setLoading: function (state, value) {
            state.loading = value;
            state.popup = value ? "loading" : "";
        },
        setShopStep: function (state, value) {
            state.shopStep = value;
        },
        logout: function (state, value) {
            state.token = null;
            state.refreshToken = null;
            state.user = {
                id: 0,
                username: "",
                walletsWithValidNFTs: [],
                powerUps: [],
                coins: 0,
                wallet: "",
            };
            state.loggedIn = false;
            Cookies.set("token", "");
            Cookies.set("refresh_token", "");
        },
        setError: function (state, value) {
            state.error = value;
        },
        setWaitingForProfile: function (state, value) {
            state.waitingForProfile = value;
        },
        setIsMobile: function (state, value) {
            state.isMobile = value;
        },
        setChains: function (state, value) {
            state.chains = value;
        },
        selectChain: function (state, value) {
            state.selectedChain = value;
        }
    },
    actions: {
        updateUser: function (_a, payload) {
            var _b;
            var commit = _a.commit;
            commit("setUser", payload);
            if (((_b = payload.walletsWithValidNFTs) === null || _b === void 0 ? void 0 : _b.length) > 0)
                commit("setUserWallet", payload.walletsWithValidNFTs[0].walletAddress);
        },
    },
    getters: {
        //@ts-ignore
        getToken: function (state) {
            return state.token;
        },
        //@ts-ignore
        getRefreshToken: function (state) {
            return state.refreshToken;
        },
        //@ts-ignore
        getUser: function (state) {
            return state.user;
        },
        getShopStep: function (state) {
            return state.shopStep;
        },
        isLogged: function (state) {
            return state.loggedIn;
        },
        connectedWallet: function (state) {
            return state.connectedWallet;
        },
        error: function (state) {
            return state.error;
        },
        waitingForProfile: function (state) {
            return state.waitingForProfile;
        },
        isMobile: function (state) {
            return state.isMobile;
        },
        getChains: function (state) {
            return state.chains;
        },
        getSelectedChain: function (state) {
            return state.selectedChain;
        }
    },
};
export default createStore({
    modules: {
        module: module,
    },
});
