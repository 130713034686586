<template>
  <div class="content">
  <div class="money-breakdown">
    <!--<div class="col-np-12 col-md-12 col-xs-12">
      <div class="header">
        <span class="font-ang">NEWS!</span>
      </div>
    </div>
    <div class="col-np-12 col-md-12 col-xs-12 tg-channel">
      <p>Join <a href="https://t.me/minersrushnews" target="_blank">Miners' Rush Announcements Channel</a> on Telegram for all news, events, airdrops and updates!</p>
    </div>
    
    <div class="col-np-12 col-md-12 col-xs-12">
      <div class="col-6 col-md-12 col-xs-12">
        <div class="news-block">
        <span class="news-title font-ang">CORE Ignition Program</span>
        <p>We're thrilled to introduce the #CoreIgnitionDrop Incentive Program Campaign! 🔥<br/><br/>


          🔗 Register with your wallet at <a href="https://ignition.coredao.org/" target="_blank">https://ignition.coredao.org/</a><br/>
          ☑️ Complete in-game tasks<br/>
          🏆 Accumulate Sparks directly from Core DAO & earn exclusive rewards!<br/><br/>

          Tasks list 📝<br/><br/>

          ✔️ Purchase an #NFT ticket and compete in the tournament<br/>
          ✔️ Play the tournament for 3 consecutive days<br/>
          ✔️ Complete 20 campaign levels<br/>
          ✔️ Accumulate 1000 points<br/>
          ✔️ Use 3 different power-ups<br/><br/>

          Bonus task 💥<br/>
          ✔️ Participate in at least 10 tournaments during the Ignition Program Campaign</p>
        </div>
      </div>

      <div class="col-6 col-md-12 col-xs-12">
        <div class="news-block">
        <span class="news-title font-ang">Miner’s Rush Clicker is LIVE!</span>
        <p>Ready for a new challenge, Miners? ⛏️<br/><br/>

        Dive into Miner’s Rush Clicker on Telegram and start earning bonuses for Miner’s Rush game! 🕹️<br/><br/>

        Tap your way to bonuses and enhance your mining! 💎<br/><br/>

        🔗 Join here: https://t.me/MinersRushClickerBot<br/><br/>

        Not mining yet? Download here! ⬇️<br/><br/>

        💎 App Store: https://apps.apple.com/lv/app/miners-rush/id6499256659<br/>
          💎 Google Play: https://play.google.com/store/apps/details?id=com.btc.dwarves</p>
        </div>
      </div>
    </div>
    
    -->
    
    <div class="col-np-12 col-md-12 col-xs-12 mt-4">
      <div class="header">
        <span class="font-ang">ABOUT THE GAME</span>
      </div>
    </div>
    <div class="col-12 col-md-12 col-xs-12">
      <p>Miner's Rush is a classic Match-3 game with a twist!</p>

      <p>Play our free campaign mode to hone your skills, or compete against other players for a blockchain-powered Daily Tournament for at least $100! What happens if there's a mining rush? The prize pool goes up!</p>

      <p>Every play in the Daily Tournament requires 1 NFT ticket. Burn your ticket, enter the tournament, and try to get the highest score! Once the day is over, the scores are tallied, and the leaderboard is announced.</p>

      <iframe width="100%" height="520" src="https://www.youtube.com/embed/HfjaBiVRlvI?si=nmud-psPktMyY0WH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowfullscreen></iframe>
      <p>The top 6 players on the leaderboard win prizes.</p>

      <p>Then, it's time to blow the whistle, shut down the mines, and reset IMMEDIATELY for a brand new Daily Tournament!</p>
      <p>To see how the prize pool works, click <span class=" here-btn font-ang"  @click="store.commit('goTo','/prizes')">here</span></p>
    </div>
    
  </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/store";
export default {
  name: 'MoneyBreakdown',
  components: {
    
  },
  props: {
    
  },
  data() {
    return {
      store
    }
  },
  methods: {

  },
  mounted() {

  },
  beforeUnmount() {

  }
}
</script>

<style lang="scss" scoped>
.content {
  overflow: hidden;
}
p {
  color: #3A3A3A;
}
.pie1 {
  
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 360px;
  height: 360px;
}
.header {
  position: relative;
  margin: 0px auto 10px;
  display: block;
}
.header span {
  line-height: 52px;
  display: block;
  margin-top:25px;
  text-align: left;
  color: #9900CC;
  font-size: 52px;
  font-weight: bold;
}
.pie-descr {
  color: #eee;
}
.pie-descr span {
  text-align: center;
  display: block;
}
.here-btn {
  color: #9900CC;
  text-decoration: underline;
  cursor: pointer;
}

.news-title {
  display: block;
  margin: 10px 0;
  color: #9900CC;
  font-size: 18px;
}
.news-block {
  background: #444;
  outline: 1px solid #eee;
  padding: 10px;
}
.news-block a, .tg-channel a {
  color: #9900CC;
}
.tg-channel {
  text-align: center;
  font-size: 18px;
}
</style>
